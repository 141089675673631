/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --header-height: 50px;
  --stepper-height: 72px;
  --notification-form-width: 400px;
  --orange: #ff7f50;
  --orange-light: #fff2ed;
  --dark-opacities-10: rgba(37, 37, 37, 0.1);
  --gradients-orange: linear-gradient(135deg, #ff9150 0%, #ff3648 100%);
  --gradients-orange-reverse: linear-gradient(-135deg, #ff7f50 0%, #ff3648 100%);
  --dark: #252525;
  --primary-hover: #ffd9ca;
  --black: #252525;
  --gray: #acacac;
  --gray-very-dark: #5f5f5f;
  --gray-dark: #848484;
  --gray-regular: #bebebe;
  --gray-light: #d2d2d2;
  --gray-very-light: #f9f9f9;
  --white: #ffffff;
  --info: #1e90ff;
  --info-shadow: #1e90ff4d;
  --success: #2ed573;
  --success-shadow: #2ed5734d;
  --danger: #ff4757;
  --danger-shadow: #ff47574d;
  --warning: #ffa502;
  --warning-shadow: #ffa5024d;
  --vh: 1vh;
}

html {
  overflow-y: hidden;
  body {
    height: 100%;
    overflow-y: hidden;

    // adds support for grabbing cursor when dragging
    // https://stackoverflow.com/questions/53674488/how-can-i-change-the-cursor-when-dragging-material-cdk-drag-and-drop
    &.inheritCursors * {
      cursor: inherit !important;
    }

    input,
    textarea {
      border-radius: 5px;
      width: 100%;
      background: rgba(37, 37, 37, 0.03);
      padding: 8px 16px;
      transition: all 0.3s ease;
      &:focus-visible {
        outline: none;
      }
      &:focus-within {
        box-shadow: 0 0 0 3px rgba(255, 127, 80, 0.3);
      }
      &:hover:not(:has(input:disabled)) {
        background: rgba(37, 37, 37, 0.1);
      }
    }

    .design-option-container {
      @apply size-[60px] bg-[#f9f9f9] rounded flex justify-center items-center cursor-pointer;

      transition: all 200ms ease-in-out;

      &:hover:not(.selected) {
        box-shadow: 0 0 0 2px #e6e6e6;
      }

      &.selected {
        background-color: #fff0ea;
        box-shadow: 0 0 0 2px var(--orange);
      }
    }
  }

  .disable-scroll {
    overflow: hidden;
  }
}
